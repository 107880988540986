import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Layout from '../components/layout';
import CtaDeck from '../components/common/cta-deck';

const headMeta = {
  title: 'Our Clients | Pandev Law',
  description:
    'Learn more about Pandev Law&#39;s areas of specialization and the types of clients and business we serve. Click to find out more about our services.',
  keywords: '',
};

const schema = null;

const offset = 119;

function Clients({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <div className="section-pale pt-5 pb-5 pt-md-10">
        <div className="container">
          <h1 className="text-hero mb-5 mb-md-10">Our Clients</h1>
          <div className="d-block">
            <AnchorLink
              offset={offset}
              href="#corporations"
              className="text-lead link-grey js-scroll-to"
              data-bs-toggle="button">
              Corporations
            </AnchorLink>
            <span className="text-lead text-steel ml-3 mr-3">|</span>
            <AnchorLink
              offset={offset}
              href="#startups"
              className="text-lead link-grey js-scroll-to">
              Startups
            </AnchorLink>
            <span className="text-lead text-steel ml-3 mr-3">|</span>
            <AnchorLink
              offset={offset}
              href="#individuals"
              className="text-lead link-grey js-scroll-to">
              Individuals
            </AnchorLink>
            <span className="text-lead text-steel ml-3 mr-3">|</span>
            <AnchorLink
              offset={offset}
              href="#technology"
              className="text-lead link-grey js-scroll-to">
              Technology
            </AnchorLink>
            <span className="text-lead text-steel ml-3 mr-3">|</span>
            <AnchorLink
              offset={offset}
              href="#education"
              className="text-lead link-grey js-scroll-to">
              Education &amp; Research
            </AnchorLink>
            <span className="text-lead text-steel ml-3 mr-3">|</span>
            <AnchorLink
              offset={offset}
              href="#investors"
              className="text-lead link-grey js-scroll-to">
              Foreign Investors
            </AnchorLink>
          </div>
        </div>
      </div>
      <div className="section pt-5 pb-5 pt-md-10 pb-md-10">
        <div className="container">
          <div id="corporations" className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid"
                src="../assets/img/men-talking.jpg"
                alt="Corporation clients Pandev Law"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">Corporations</h2>
              <p className="text-body mb-6">
                Whether you are a small business or a multinational corporation, I can
                assist you with your business and immigration law needs. I develop
                business immigration solutions tailored to your field and effectively
                represent you in business transactions regardless of your industry. I have
                successfully represented companies in various sectors, including
                technology, financial services, hospitality, manufacturing,
                transportation, healthcare and construction industries.
              </p>
              <p className="text-headline mb-4">
                <strong>Related services:</strong>
              </p>
              <div className="row">
                <div className="col-6 mb-4 mb-md-6">
                  <h3 className="text-lead mb-3">
                    <strong>Employment-Based Immigration</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Hiring of Foreign Employees</li>
                    <li>Employment-Based Green Cards</li>
                    <li>Immigration Compliance</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/employment-based-visa-immigration">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6 mb-4 mb-md-6">
                  <h3 className="text-lead mb-3">
                    <strong>Corporate Law</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Contract Drafting &amp; Review</li>
                    <li>Buy or Sell a Business</li>
                    <li>Business Disputes</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/business-law-services">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6">
                  <h3 className="text-lead mb-3">
                    <strong>International Business Law</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Customs &amp; International Trade Law</li>
                    <li>Cross-border Transactions</li>
                    <li>Foreign Direct Investment</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/business-law-services">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6">
                  <h3 className="text-lead mb-3">
                    <strong>General Counsel</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Corporate Governance &amp; Maintenance</li>
                    <li>Transaction Advice &amp; Negotiation</li>
                    <li>Compliance Services</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/business-law-services">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div id="startups" className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/people-discussing.jpg"
                alt="Startup clients Pandev Law"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Startups</h2>
              <p className="text-body mb-6">
                <strong>
                  Whether you are an entrepreneur wishing to start a new a business or an
                  early-stage startup company, I provide effective legal representation
                  for all your startup law needs.
                </strong>
                <br />
                <br />I love innovation and watching a company grow from an idea to a
                successful business. I represent startups in various business transactions
                which help your company grow, including company formation, financing, and
                contract matters.
                <br />
                <br />
                As an immigration lawyer, I have helped many foreign entrepreneurs and
                university students start a business in the US, including advising on
                immigration compliance and obtaining an entrepreneur visa. Regardless of
                your industry, I can assist your startup in business and immigration law
                matters. I have represented e-commerce, technology, retail, fashion,
                marketing, health & fitness, construction, and financial services
                startups, among many others.
              </p>
              <p className="text-headline mb-4">
                <strong>Related services:</strong>
              </p>
              <div className="row">
                <div className="col-6 mb-4 mb-md-6">
                  <h3 className="text-lead mb-3">
                    <strong>Startup Law</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Company Formation Package</li>
                    <li>Financing Transactions</li>
                    <li>General Counsel</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/business-law-services">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6 mb-4 mb-md-6">
                  <h3 className="text-lead mb-3">
                    <strong>Employment-Based Immigration</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Employment-Based Visa</li>
                    <li>Employment-Based Green Card</li>
                    <li>Immigration Compliance</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/employment-based-visa-immigration">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6">
                  <h3 className="text-lead mb-3">
                    <strong>Investor Immigration</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>EB-5 Investor Green Card</li>
                    <li>E-2 Treaty Investor Visa</li>
                    <li>L-1 New US Office Investor Visa</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/investor-visa-usa-immigration">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6">
                  <h3 className="text-lead mb-3">
                    <strong>Foreign Direct Investment</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Customs &amp; Trade Law</li>
                    <li>Transaction Planning &amp; Structuring</li>
                    <li>International Mergers &amp; Acquisitions</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/business-law-services">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div id="individuals" className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/woman-with-eyeglasses.jpg"
                alt="Individuals clients Pandev Law"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">Individuals</h2>
              <p className="text-body mb-6">
                <strong>
                  I represent individuals in various business and immigration law matters.
                </strong>
                <br />
                <br />I understand that you have a unique story, so I always tailor my
                advice to your specific needs. Whether you are seeking to sponsor a green
                card for a family member, apply for US citizenship, or obtain an artist or
                athlete visa, I devise customized immigration strategies for your needs.
                <br />
                <br />
                As a business lawyer, I regularly represent individual investors,
                shareholders, and interest holders in business disputes and transactions.
                I also advocate for freelancers and advise individual entrepreneurs on
                starting a business.
              </p>
              <p className="text-headline mb-4">
                <strong>Related services:</strong>
              </p>
              <div className="row">
                <div className="col-6 mb-4 mb-md-6">
                  <h3 className="text-lead mb-3">
                    <strong>Individual &amp; Family Immigration</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Family-Based Immigration</li>
                    <li>EB-1 &amp; EB-2 NIW Green Card</li>
                    <li>US Citizenship</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/family-based-green-card-immigration">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6 mb-4 mb-md-6">
                  <h3 className="text-lead mb-3">
                    <strong>Startup Law</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Company Formation</li>
                    <li>Contract Drafting &amp; Review</li>
                    <li>Financing Transactions</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/business-law-services">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6">
                  <h3 className="text-lead mb-3">
                    <strong>Employment-Based Immigration</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>O-1 Artist &amp; Athlete Visa</li>
                    <li>P-1 Athlete Visa</li>
                    <li>Litigation &amp; Appeals</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/employment-based-visa-immigration">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6">
                  <h3 className="text-lead mb-3">
                    <strong>Business Disputes</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Contract Disputes</li>
                    <li>Freelancer Disputes under NYC</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/business-law-services">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div id="technology" className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/woman-vr.jpg"
                alt="Technology clients Pandev Law"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Technology</h2>
              <p className="text-body mb-6">
                <strong>
                  I represent innovators and technology companies ranging from early phase
                  startups to established tech companies.
                </strong>
                <br />
                <br />I have always been fascinated with new technologies and how they
                propel us towards the future. I advise tech companies on various business
                transactions, including software licensing, intellectual property, and
                financing.
                <br />
                <br />
                As an immigration lawyer, I regularly help tech companies hire foreign
                engineers, computer specialists, IT professionals, and scientists. I am a
                believer in the potential of blockchain technology, and have developed a
                niche in representing blockchain startups.
              </p>
              <p className="text-headline mb-4">
                <strong>Related services:</strong>
              </p>
              <div className="row">
                <div className="col-6 mb-4 mb-md-6">
                  <h3 className="text-lead mb-3">
                    <strong>Technology Law</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Software Licensing Agreements</li>
                    <li>Trademark Registration</li>
                    <li>Copyright Registration</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/business-law-services">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6 mb-4 mb-md-6">
                  <h3 className="text-lead mb-3">
                    <strong>Employment-Based Immigration</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Hiring of Foreign Employees</li>
                    <li>Employment-Based Green Card</li>
                    <li>Immigration Compliance</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/employment-based-visa-immigration">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6">
                  <h3 className="text-lead mb-3">
                    <strong>Individual Immigration</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>H-1B &amp; O-1 Visa</li>
                    <li>EB-1 &amp; EB-2 NIW Green Card</li>
                    <li>US Citizenship</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/family-based-green-card-immigration">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6">
                  <h3 className="text-lead mb-3">
                    <strong>Corporate Law</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Company Formation</li>
                    <li>Corporate Governance &amp; Maintenance</li>
                    <li>Contract Drafting &amp; Review</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/business-law-services">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div id="education" className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/woman-speaking.jpg"
                alt="Education and research clients Pandev Law"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">Education &amp; Research</h2>
              <p className="text-body mb-6">
                <strong>
                  Diversity in faculty, staff, and student body has greatly enriched the
                  US education system.
                </strong>
                <br />
                <br />I represent public and private schools, and colleges in hiring
                foreign employees in compliance with immigration laws and regulations. As
                an immigration lawyer, I help research labs and biotechnology startups
                sponsor visas and green cards for their foreign researchers. I also have
                experience representing Postdoctoral Researchers in obtaining EB-1 and
                EB-2 National Interest Waiver green cards.
                <br />
                <br />
                On the business side, I counsel education and research institutions in
                various transactions, ranging from financing and corporate governance to
                trademark and copyright registration.
              </p>
              <p className="text-headline mb-4">
                <strong>Related services:</strong>
              </p>
              <div className="row">
                <div className="col-6 mb-4 mb-md-6">
                  <h3 className="text-lead mb-3">
                    <strong>Employment-Based Immigration</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Hiring of Foreign Employees</li>
                    <li>Employment-Based Green Card</li>
                    <li>Immigration Compliance</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/employment-based-visa-immigration">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6 mb-4 mb-md-6">
                  <h3 className="text-lead mb-3">
                    <strong>Individual Immigration</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>H-1B & O-1 Visa</li>
                    <li>EB-1 & EB-2 NIW Green Card</li>
                    <li>US Citizenship</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/family-based-green-card-immigration">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6">
                  <h3 className="text-lead mb-3">
                    <strong>Technology Law</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Software Licensing Agreements</li>
                    <li>Trademark Registration</li>
                    <li>Copyright Registration</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/business-law-services">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6">
                  <h3 className="text-lead mb-3">
                    <strong>Corporate Law</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Corporate Governance & Maintenance</li>
                    <li>Contract Drafting & Review</li>
                    <li>Business Disputes</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/business-law-services">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div id="investors" className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/man-with-book.jpg"
                alt="Investor immigration clients Pandev Law"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Foreign Investors</h2>
              <p className="text-body mb-6">
                <strong>
                  I represent foreign investors who wish to immigrate to the US or start a
                  business in the US.
                </strong>
                <br />
                <br />
                As an experienced investor immigration lawyer, I serve as your trusted
                counsel from the initial investment plan until you have achieved your
                immigration objective, whether that is an EB-5 green card or an E-2 treaty
                investor visa.
                <br />
                <br />I rely on my business law experience to effectively represent your
                business in various transactions that foster your company’s growth. My
                goal is to help you achieve your dream of owning a successful business in
                the US.
              </p>
              <p className="text-headline mb-4">
                <strong>Related services:</strong>
              </p>
              <div className="row">
                <div className="col-6 mb-4 mb-md-6">
                  <h3 className="text-lead mb-3">
                    <strong>Investor Immigration</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>EB-5 Investor Green Card</li>
                    <li>E-2 Treaty Investor Visa</li>
                    <li>L-1 New US Office Investor Visa</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/investor-visa-usa-immigration">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6 mb-4 mb-md-6">
                  <h3 className="text-lead mb-3">
                    <strong>Corporate Law</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Corporate Governance &amp; Maintenance</li>
                    <li>Contract Drafting &amp; Review</li>
                    <li>Buy or Sell a Business</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/business-law-services">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6">
                  <h3 className="text-lead mb-3">
                    <strong>Startup Law</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Company Formation</li>
                    <li>Financing Transactions</li>
                    <li>General Counsel</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/business-law-services">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
                <div className="col-6">
                  <h3 className="text-lead mb-3">
                    <strong>Foreign Direct Investment</strong>
                  </h3>
                  <ul className="list-bullets text-grey text-small mb-3">
                    <li>Customs & Trade Law</li>
                    <li>Transaction Planning & Structuring</li>
                    <li>International Mergers & Acquisitions</li>
                  </ul>
                  <AnchorLink
                    offset={offset}
                    className="link-primary"
                    href="/business-law-services">
                    Learn more &raquo;
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CtaDeck />
    </Layout>
  );
}

export default Clients;
